export function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

export function setCookie(name, value) {
    const expiredDate = new Date();
    expiredDate.setDate(expiredDate.getDate() + 1);
    document.cookie = `${name}=${value}; expires=${expiredDate.toUTCString()}`;
}