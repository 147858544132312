import React, { useState } from 'react';
import { authenticate } from '../utils/auth';
import { setCookie } from '../utils/cookies';
import { encryptWithAES } from '../utils/crypto';

const Auth = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const submitForm = async (e) => {
        e.preventDefault(); 
        const credential = await authenticate(username, password);

        if (credential) {
            setCookie(encryptWithAES('credential'), credential);
            window.location.href = './main';
        } else {
            alert('Username atau password tidak valid');
        }
    };

    return (
        <div className="doodle-bg bg-gray-100 text-gray-900 min-h-screen flex items-center justify-center">
            <style jsx>{`
                .btn {
                    transition: transform 0.2s, background-color 0.3s;
                    background-color: #4A4A4A; /* Warna abu-abu */
                    color: #fff;
                    padding: 0.75rem 2rem; /* Ukuran button lebih besar */
                    font-size: 1rem; /* Ukuran teks button lebih besar */
                    border-radius: 1rem; /* Border radius lebih besar untuk tampilan manis */
                    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1); /* Tambahkan bayangan untuk efek manis */
                    margin-top: 2rem; /* Jarak antar tombol ditingkatkan */
                    width: 100%; /* Lebar penuh */
                    text-align: center; /* Teks di tengah */
                    border: 1px solid rgba(0, 0, 0, 0.1); /* Border sama dengan input */
                    cursor: pointer;
                }
                .btn:hover {
                    transform: scale(1.05);
                    background-color: #2C2C2C; /* Warna abu-abu lebih gelap saat hover */
                }
                .doodle-bg {
                    margin: 0;
                    padding: 0;
                    background-image: url('https://images5.alphacoders.com/390/390081.jpg');
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    height: 100vh;
                    width: 100vw;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                body {
                    filter: none; /* Hilangkan filter grayscale */
                }
                .form-container {
                    background: rgba(255, 255, 255, 0.9); /* Warna putih dengan transparansi */
                    backdrop-filter: blur(10px);
                    padding: 2rem;
                    border-radius: 1rem; /* Border radius lebih besar untuk tampilan modern */
                    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                    max-width: 400px;
                }
                .input-field {
                    background: rgba(255, 255, 255, 0.8);
                    border: 1px solid rgba(0, 0, 0, 0.1); /* Border sama dengan button */
                    padding: 0.75rem;
                    border-radius: 0.5rem;
                    width: calc(100% - 1.5rem); /* Sesuaikan lebar dengan button */
                    margin-top: 0.5rem;
                    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1); /* Tambahkan bayangan untuk input */
                    transition: box-shadow 0.3s ease; /* Transisi untuk bayangan */
                }
                .input-field:focus {
                    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2); /* Bayangan lebih besar saat fokus */
                    outline: none; /* Hilangkan outline default */
                }
                .label {
                    font-weight: bold; /* Menebalkan label */
                }
            `}</style>
            <div className="form-container">
                <h1 className="text-4xl font-bold mb-6 text-center text-gray-800">Login</h1>
                <form className="space-y-4" onSubmit={submitForm}>
                    <div className="form-row">
                        <label htmlFor="username" className="label block text-sm font-medium text-gray-700">Username</label>
                        <input type="email" id="username" name="username" value={username} onChange={(e) => setUsername(e.target.value)} className="input-field mt-1 block py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="MS Teams email" required />
                    </div>
                    <div className="form-row" style={{ marginTop: '1rem' }}>
                        <label htmlFor="password" className="label block text-sm font-medium text-gray-700">Password</label>
                        <input type="password" id="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} className="input-field mt-1 block py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="MS Teams password" required />
                    </div>
                    <button type="submit" className="btn block text-center bg-gray-700 text-white py-2 px-4 rounded-lg shadow-lg hover:bg-gray-800 transition duration-300">Login</button>
                </form>
            </div>
        </div>
    );
};

export default Auth;