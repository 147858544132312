import CryptoJS from 'crypto-js';


export const encryptWithAES = (text) => {
    const encrypted = CryptoJS.AES.encrypt(
        CryptoJS.enc.Utf8.parse(text),
        CryptoJS.enc.Utf8.parse(process.env.REACT_APP_SECRET), { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }
    );
    const hexString = encrypted.ciphertext.toString(CryptoJS.enc.Hex);
    return hexString;
};

export const decryptWithAES = (text) => {
    const encryptedHex = CryptoJS.enc.Hex.parse(text);
    const encryptedBase64 = CryptoJS.enc.Base64.stringify(encryptedHex);
    const decrypted = CryptoJS.AES.decrypt(
        encryptedBase64,
        CryptoJS.enc.Utf8.parse(process.env.REACT_APP_SECRET), { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }
    );
    const hexString = decrypted.toString(CryptoJS.enc.Utf8);
    return hexString;
};

export const decryptWithAESJWT = (text) => {
    const encryptedHex = CryptoJS.enc.Hex.parse(text);
    const encryptedBase64 = CryptoJS.enc.Base64.stringify(encryptedHex);
    const decrypted = CryptoJS.AES.decrypt(
        encryptedBase64,
        CryptoJS.enc.Utf8.parse(process.env.REACT_APP_JWT_AUTH_DECRYPTED), { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }
    );
    const hexString = decrypted.toString(CryptoJS.enc.Utf8);
    return hexString;
};
