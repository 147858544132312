import React from 'react';
import Auth from './pages/auth';
import Main from './pages/main';
import { getCookie } from './utils/cookies';
import { encryptWithAES } from './utils/crypto';

function App() {
  const hasCredentials = checkCredentials();

  return (
    <div className="App">
      {hasCredentials ? <Main /> : <Auth />}
    </div>
  );
}

function checkCredentials() {
  const credential = getCookie(encryptWithAES('credential'));
  if (credential) {
    return true;
  }
  return false;
}

export default App;